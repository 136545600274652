<template>
  <div>
    <header class="claims-header">
      <div @click="toggleCollapse()" class="d-flex align-items-start">
        <div class="col-auto me-2 title-group">
          <h6 class="text-nowrap mb-0 primary-title">Follow up Question:</h6>
        </div>
        <div class="col">
          <h6 class="question mb-0">{{ q.question }}</h6>
        </div>
      </div>

      <div class="action-buttons" v-if="q.answer !== null">
        <button
          :disabled="isCopied"
          class="action-btn copy-btn"
          tabindex="0"
          @click="copy"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
            alt="Copy content"
            class="action-icon"
          />
          <span>{{ isCopied ? "Copied" : "Copy" }}</span>
        </button>

        <b-dropdown
          size="sm"
          id="dropdown-right"
          right
          offset="-9"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          @click="toggleDropdown"
        >
          <template #button-content>
            <button class="action-btn export-btn">
              <span class="button-label">Export</span>
              <img
                :class="{ 'rotate-180': isDropdown }"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                alt="Export Icon"
                class="icon"
              />
            </button>
          </template>

          <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
            <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
            <span class="dropdown-text">Export as PDF</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="hasTable"
            class="ms-1 me-1"
            @click="exportToExcel"
          >
            <img class="me-2" src="@/assets/icon/excel-icon.svg" />
            <span class="dropdown-text">Export as Excel</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </header>

    <template v-if="q.answer !== null">
      <div
        v-if="isCollapsed"
        style="padding: 32px 32px 32px 0"
        id="marked-style"
      >
        <div v-html="marked.parse(q.answer)"></div>
      </div>
    </template>

    <section class="skeleton-loader mt-4" v-else>
      <div class="skeleton-item skeleton-item-large"></div>
      <div class="skeleton-item skeleton-item-full"></div>
      <div class="skeleton-item skeleton-item-full"></div>
    </section>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

import marked from "marked";
import { ExportDataPDF } from "@/store/utils";

export default {
  props: {
    q: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
      isDropdown: false,
      isCopied: false,
    };
  },

  computed: {
    hasTable() {
      const htmlContent = marked(this.q.answer);

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      return doc.querySelector("table") !== null;
    },
  },
  created() {
    this.marked = marked;

    const renderer = new marked.Renderer();

    renderer.table = function (header, body) {
      return `<table class="table">\n<thead>\n${header}</thead>\n<tbody>\n${body}</tbody>\n</table>\n`;
    };

    renderer.list = function (body, ordered) {
      const type = ordered ? "ol" : "ul";
      return `<${type}>\n${body}</${type}>\n`;
    };

    renderer.listitem = function (text) {
      return `<li>${text}</li>\n`;
    };

    this.marked.setOptions({
      gfm: true,
      breaks: true,
      renderer: renderer,
    });
  },
  methods: {
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    copy() {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = marked.parse(this.q.answer);

      const precedingText = this.getPrecedingText(tempDiv);

      let csvContent = precedingText ? `${precedingText.trim()}\n\n` : "";

      const tables = tempDiv.querySelectorAll("table");
      tables.forEach((table) => {
        const rows = table.querySelectorAll("tr");
        rows.forEach((row) => {
          const cells = row.querySelectorAll("td, th");
          const rowArray = Array.from(cells).map((cell) =>
            cell.innerText.trim()
          );
          if (rowArray.some((cell) => cell !== "")) {
            csvContent += rowArray.join("\t") + "\n";
          }
        });
        csvContent += "\n";
      });

      navigator.clipboard
        .writeText(csvContent)
        .then(() => {
          this.$toast.success("Copied to clipboard as plain text");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying to clipboard");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    getPrecedingText(tempDiv) {
      let text = "";

      const firstTable = tempDiv.querySelector("table");
      const nodes = Array.from(tempDiv.childNodes);

      for (const node of nodes) {
        if (node === firstTable) {
          break;
        }
        if (node.nodeType === Node.TEXT_NODE) {
          text += node.textContent.trim() + "\n";
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          text += node.innerText.trim() + "\n";
        }
      }

      return text;
    },
    exportToPDF() {
      const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Campaign - ${this.$route.params.id} : ${this.q.question}</h1><br><hr>`;
      const tempDiv = document.createElement("div");

      // Style the temporary div
      tempDiv.style.width = "65%";
      tempDiv.style.maxWidth = "800px";
      tempDiv.style.margin = "20px auto";
      tempDiv.style.padding = "20px";
      tempDiv.style.border = "1px solid #ccc";
      tempDiv.style.borderRadius = "10px";
      tempDiv.style.backgroundColor = "#f9f9f9";
      tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
      tempDiv.style.fontFamily = "Arial, sans-serif";

      let summary = marked(this.q.answer);
      tempDiv.innerHTML = followUpQuestion + summary;

      document.body.appendChild(tempDiv);
      ExportDataPDF(
        tempDiv.innerHTML,
        false,
        `Campaign: ${this.$route.params.id}`
      );
      this.$toast.success("Successfully downloaded PDF");
      document.body.removeChild(tempDiv);
    },
    exportToExcel() {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = marked(this.q.answer);

      const tableElement = tempDiv.querySelector("table");

      if (!tableElement) {
        console.error("No table found in the provided HTML string.");
        return;
      }

      const rows = Array.from(tableElement.querySelectorAll("tr"));
      let csv = "";

      rows.forEach((row) => {
        const cols = Array.from(row.querySelectorAll("td, th"));

        if (
          cols.length > 0 &&
          cols.some((col) => col.innerText.trim() !== "")
        ) {
          const csvRow = cols
            .map((col) => `"${col.innerText.replace(/"/g, '""')}"`)
            .join(",");
          csv += csvRow + "\n";
        }
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `Campaign : ${this.$route.params.id}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const wb = XLSX.utils.table_to_book(tableElement, { sheet: "Sheet1" });
      XLSX.writeFile(wb, `Campaign : ${this.$route.params.id}.xlsx`);

      this.$toast.success(
        `Exported Campaign ${this.$route.params.id} to CSV and XLSX`
      );
    },
  },
};
</script>

<style scoped>
#marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

#marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

#marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

#marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

#marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

#marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

#marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

#marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

#marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

#marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 700 !important;
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}

#marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

#marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 500 !important;
  margin-bottom: 1.25rem !important;
  margin-top: 1.25rem !important;
}

#marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

#marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

#marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

#marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.claims-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 40px;
}

.title-group {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 8px;
  line-height: 28px;
}

.primary-title {
  color: #0e4485;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-text {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.claims-content {
  margin: 32px 0;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.case-summary {
  margin: 32px 0;
  border-collapse: collapse;
}

.case-summary th {
  padding: 9px 20px 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: 1px solid var(--Neutral-Dark-Grey, #86888d);
  border-radius: 8px;
}

.case-summary td {
  padding: 9px 4px 9px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Dark-Grey, #86888d);
  border-bottom: 1px solid var(--Neutral-Dark-Grey, #86888d) !important;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.skeleton-item {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
  min-height: 28px;
}

.skeleton-item-large {
  width: 535px;
  max-width: 100%;
}

.skeleton-item-full {
  width: 100%;
  margin-top: 8px;
}

article {
  font-family: "Poppins", sans-serif !important;
  line-height: 1.6 !important;
  margin: 0 auto !important;
  max-width: 800px !important;
  padding: 20px !important;
  color: #1f2937 !important;
  background-color: #ffffff !important;
}
#string-answer * > strong,
#string-answer strong {
  font-weight: 700 !important;
  color: #111827 !important;
  background-color: #fffae5 !important;
  padding: 0.2rem 0.4rem !important;
  border-radius: 4px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e3a8a !important;
  margin: 0.5rem 0 !important;
}

h1 {
  font-size: 2.25rem !important;
  font-weight: 700 !important;
  border-bottom: 2px solid #e0e7ff !important;
  padding-bottom: 0.5rem !important;
}

h2 {
  font-size: 1.875rem !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 1.625rem !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

h5 {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

h6 {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-weight: 700 !important;
  color: #111827 !important;
  background-color: #fffae5 !important;
  padding: 0.2rem 0.4rem !important;
  border-radius: 4px !important;
}

strong {
  font-weight: 700 !important;
  color: #111827 !important;
  background-color: #fffae5 !important;
  padding: 0.2rem 0.4rem !important;
  border-radius: 4px !important;
}

p {
  font-size: 1rem !important;
  margin: 1rem 0 !important;
  line-height: 1.7 !important;
  color: #4b5563 !important;
}

a {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  transition: color 0.3s ease !important;
}

a:hover {
  color: #2563eb !important;
}

blockquote {
  border-left: 4px solid #dddddd !important;
  margin: 1rem 0 !important;
  padding-left: 1rem !important;
  color: #6b7280 !important;
  font-style: italic !important;
}

@media (max-width: 600px) {
  article {
    padding: 10px !important;
  }

  h1 {
    font-size: 1.75rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  h3 {
    font-size: 1.25rem !important;
  }

  p {
    font-size: 0.9rem !important;
  }
}

em {
  font-style: italic !important;
  color: #4b5563 !important;
}

small {
  font-size: 0.875rem !important;
  color: #6b7280 !important;
}

u {
  text-decoration: underline !important;
  color: #1e3a8a !important;
}

mark {
  background-color: #fef08a !important;
  color: #1f2937 !important;
}

del {
  text-decoration: line-through !important;
  color: #9ca3af !important;
}

code {
  font-family: "Courier New", Courier, monospace !important;
  background-color: #f3f4f6 !important;
  padding: 0.2rem 0.4rem !important;
  border-radius: 4px !important;
}

blockquote {
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  color: #6b7280 !important;
  font-style: italic !important;
}

@media (max-width: 600px) {
  strong,
  em,
  mark,
  code {
    font-size: 0.9rem !important;
  }
}
</style>
